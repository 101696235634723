<script>
	import { locale } from 'svelte-i18n';
	import tippy from 'sveltejs-tippy';
	import HelpIcon from '$lib/icon/HelpIcon.svelte';

	export let content = '';
	export let ariaLabel = '';
	export let placement = 'top';
	export let css = '';
</script>

<button
	use:tippy={{
		content,
		placement
	}}
	aria-label={ariaLabel}
	class="{$locale && $locale.startsWith('ar') ? 'mr-1' : 'ml-1'} {css}"
>
	<HelpIcon />
</button>
