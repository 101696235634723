<script>
	import { createEventDispatcher } from 'svelte';
	import { slide } from 'svelte/transition';
	import Dropdown from './Dropdown.svelte';
	import ManagedProvider from './ManagedProvider.svelte';
	import { DEFAULT_MANAGED_LOGO } from '$src/constants.js';

	export let expanded = false;
	export let label = '';
	export let login = false;
	export let showIcon = true;
	export let hasBackground = false;
	export let accountSelected = undefined;
	export let handleDropdown = undefined;

	const dispatch = createEventDispatcher();
</script>

<Dropdown
	dataTest="managed-provider-btn"
	ariaLabel={label}
	{expanded}
	{hasBackground}
	on:click={() => {
		if (handleDropdown) handleDropdown('managedProvider');
		else expanded = !expanded;
	}}
>
	<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
		{#if showIcon}
			<span class="managed-rounded-square-sm">
				<img src={DEFAULT_MANAGED_LOGO} alt="logo" class="w-4.5 max-h-[18px] object-contain" />
			</span>
		{/if}
		<span class="block text-left" aria-hidden="true">
			{label}
		</span>
	</div>

	{#if expanded}
		<div class="rounded-md text-left w-full z-30 p-4 flex flex-col" transition:slide|local>
			<ManagedProvider
				{login}
				{accountSelected}
				on:managedEmailSuccess={() => dispatch('managedEmailSuccess')}
			/>
		</div>
	{/if}
</Dropdown>
