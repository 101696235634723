const trimEthAddress = (addr) => {
	return addr.slice(0, 6) + '...' + addr.slice(38);
};

const getWallet = () => {
	if (window.ethereum?.isMetaMask) {
		return {
			slug: 'metamask',
			name: 'MetaMask',
			icon: 'https://cdn.hello.coop/images/metamask.svg'
		};
	} else if (window.ethereum) {
		return {
			slug: 'ethereum',
			name: 'Ethereum',
			icon: 'https://cdn.hello.coop/images/ethereum.svg'
		};
	} else {
		return false;
	}
};

export { trimEthAddress, getWallet };
