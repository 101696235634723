const makeMastodonPreviewDataObj = (obj) => {
	const _obj = structuredClone(obj); //creates a deep clone
	return {
		id: _obj.id,
		avatar: _obj.avatar,
		bio: _obj.source?.note,
		header: _obj.header,
		metadata: _obj.source?.fields
	};
};

const mastodonSeed = {
	'c.im': {},
	'counter.social': {},
	'fosstodon.org': {},
	'gc2.jp': {},
	'home.social': {},
	'mas.to': {},
	'masto.ai': {},
	'mastodon.cloud': {},
	'mastodon.hello-dev.net': {},
	'mastodon.hello-local.net': {},
	'mastodon.lol': {},
	'mastodon.online': {},
	'mastodon.social': {},
	'mastodon.uno': {},
	'mastodon.world': {},
	'mastodonapp.uk': {},
	'mstdn.jp': {},
	'mstdn.social': {},
	'pawoo.net': {},
	'pixelfed.social': {},
	'social.coop': {},
	'techhub.social': {},
	'universeodon.com': {},
	'verified-local.net': {
		hello_login: true
	},
	'verified-staging.net': {
		hello_login: true
	},
	'verified.coop': {
		hello_login: true
	}
};

export { makeMastodonPreviewDataObj, mastodonSeed };
