<script>
	import { _ } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import { data, showSpinner } from '$src/stores.js';
	import { onMount, createEventDispatcher } from 'svelte';
	import SvelteOtp from '@hellocoop/svelte-otp';
	import {
		postLoginEmail,
		postLinkEmail,
		postVerifyEmail,
		postVerifyEmailCode,
		postLoginEmailCode,
		postLinkEmailCode,
		postLoginProvider,
		getEmailProviderDiscovery,
		postLinkProvider
	} from '$utils/api.js';
	import { getDisplay } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import LoginProvider from './LoginProvider.svelte';
	import { emailDomains } from '$utils/email.js';
	import logins from '$svr/providers/logins.json';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import { isValidEmail } from '$utils/email.js';

	const dispatch = createEventDispatcher();

	// export let noautofocus = false;
	export let disabled = false;
	export let email = '';

	export let login = false;
	export let accountToUse = undefined;
	export let accountSelected = undefined;
	export let useDifferentManaged = undefined;
	export let choosePreferred = undefined;
	export let verify = false;

	export let emailOTPState = false;

	export let recommendedProvider = false;

	let showProviderPrompt = false; //also hold slug

	let otp = '';
	let ajaxRequestSend = false;
	let ajaxRequestResend = false;
	export let verifyOTPAjax = false;
	let verifiedEmails = [];

	onMount(() => {
		// if (!disabled && !noautofocus) document.getElementById('email').focus();
		if (($data?.isPersonalLoggedIn || $data?.isManagedLoggedIn) && !login && !verify) {
			if (window.isWalletAuthorizeApp && Array.isArray($data.release?.emails)) {
				let _verifiedEmails = [];
				//dont block verifying unverified emails
				if (Array.isArray($data.release?.unverified_emails)) {
					_verifiedEmails = $data.release.emails.filter(
						(email) => !$data.release.unverified_emails.includes(email)
					);
				} else {
					_verifiedEmails = $data.release.emails;
				}
				verifiedEmails = _verifiedEmails;
			} else if (
				Array.isArray($data.profile?.accounts) &&
				Array.isArray($data.profile?.unverified_emails)
			) {
				const _verifiedEmails = $data.profile.accounts
					.filter(
						(i) => i.slug === 'email' && !$data.profile.unverified_emails.includes(i.user_name)
					)
					.map((i) => i.user_name);
				verifiedEmails = _verifiedEmails;
			}
		}
	});

	async function verifyEmail(email, resend) {
		try {
			if (login) {
				await postLoginEmail(email, resend);
			} else if (verify) await postVerifyEmail(email, resend);
			else await postLinkEmail(email, resend);

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
				//session funnel state is valid and not already sent + is authorize app
				if (
					login &&
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					logPlausibleEvent({
						n: 'AZ Login Start',
						p: {
							client_id,
							provider: 'email',
							recommended_provider: recommendedProvider,
							redirect
						},
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_start');
				}
			}

			//Wizard Funnel
			const isInWizard = !$data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
			//is trying to link + is in wizard + is wallet app
			if (!login && !verify && isInWizard && window.isWalletApp) {
				const preferred = $data?.preferred?.[0]?.slug;
				let welcome_email_app;
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
				const recovery_1 = $data?.recovery?.[0]?.slug;
				if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 1 Start',
						p: { preferred, welcome_email_app, recovery_1: 'email' },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
				} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 2 Start',
						p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
				}
			}

			emailOTPState = true;
			dispatch('otp');
		} catch (err) {
			// do nothing - we are already handling error in parent layer
		} finally {
			ajaxRequestSend = ajaxRequestResend = false;
		}
	}

	async function verifyEmailCode(code) {
		try {
			verifyOTPAjax = true;
			if (login) {
				await postLoginEmailCode({
					code,
					accountToUse,
					accountSelected,
					useDifferentManaged,
					choosePreferred
				});
			} else if (verify) {
				await postVerifyEmailCode(code);
			} else {
				await postLinkEmailCode(code);
			}
			dispatch('success', { email });
		} catch (err) {
			otp = '';
			verifyOTPAjax = false;
			dispatch('error', err);
		}
	}

	$: if (otp.length === 6) {
		verifyEmailCode(otp);
	}

	let timer;
	let checkEmailProviderAjax = false;
	async function promptProviderLogin(e) {
		showProviderPrompt = false;
		checkEmailProviderAjax = false;
		const domain = e.target.value.split('@')[1];
		if (timer) clearTimeout(timer);
		if (!disabled && domain && isValidEmail(email)) {
			if (login) {
				for (const key in emailDomains) {
					if (emailDomains[key].includes(domain)) {
						showProviderPrompt = key;

						//Start of Email Upgrade Funnel
						//email upgrade funnel state is not already sent
						if (!sessionStorage.email_upgrade_funnel) {
							logPlausibleEvent({
								n: 'Email Upgrade Prompt',
								p: { email_domain: domain },
								u: '/login'
							});
							sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_prompt');
						}
						return;
					}
				}

				timer = setTimeout(async () => {
					try {
						checkEmailProviderAjax = true;
						const { slug } = await getEmailProviderDiscovery(domain);
						if (!slug) throw new Error();
						if (email !== e.target.value) return;

						showProviderPrompt = slug;

						//Start of Email Upgrade Funnel
						//email upgrade funnel state is not already sent
						if (!sessionStorage.email_upgrade_funnel) {
							logPlausibleEvent({
								n: 'Email Upgrade Prompt',
								p: { email_domain: slug },
								u: '/login'
							});
							sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_prompt');
						}
					} catch (err) {
						//do nothing - fail silently
					} finally {
						checkEmailProviderAjax = false;
					}
				}, 650);
			} else {
				if (verifiedEmails.includes(email)) return;

				timer = setTimeout(async () => {
					try {
						checkEmailProviderAjax = true;
						const { slug } = await getEmailProviderDiscovery(domain);
						if (!slug) throw new Error();
						if (email !== e.target.value) return;

						const provider = logins.find((i) => i.slug == slug);
						if (provider) {
							if (provider.claims?.verified_email) {
								showProviderPrompt = slug;
							}
						}
					} catch (err) {
						//do nothing - fail silently
					} finally {
						checkEmailProviderAjax = false;
					}
				}, 650);
			}
		}
	}

	async function continueWithProvider(slug, body) {
		try {
			$showSpinner = true;

			if (login) {
				const { redirect } = await postLoginProvider({
					slug,
					body
				});

				//Email Upgrade Funnel
				if (sessionStorage.email_upgrade_funnel === 'email_upgrade_prompt') {
					const email_domain = email?.split('@')?.[1];
					await logPlausibleEvent({
						n: 'Email Upgrade Start',
						p: { email_domain, slug },
						u: '/login'
					});
					sessionStorage.setItem('email_upgrade_funnel', 'email_upgrade_start');
				}
				window.location.href = redirect;
			} else {
				const { redirect } = await postLinkProvider({
					slug,
					body
				});
				window.location.href = redirect;
			}
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}
</script>

{#if !emailOTPState}
	<div id="email-wrapper" class="text-center w-full">
		{#if !disabled}
			<input
				type="email"
				name="email"
				id="email"
				autocomplete="email"
				autocapitalize="off"
				bind:value={email}
				on:input={promptProviderLogin}
				placeholder={$_('enter your email')}
				class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
			/>
		{/if}
		{#if checkEmailProviderAjax}
			<div class="flex justify-start items-center mt-2" transition:slide|local>
				<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
				<span class="text-left block opacity-80 ml-2">{$_('Checking')}</span>
			</div>
		{/if}
		{#if !login && disabled}
			<span class="h-6 flex items-center justify-center">{email}</span>
		{/if}

		{#if (login && showProviderPrompt) || (!login && !verifiedEmails.includes(email) && showProviderPrompt)}
			<div data-test="provider-prompt" class="mt-5" transition:slide|local>
				<h3 class="w-3/4 mx-auto font-semibold mb-3">
					{login
						? $_('Looks like you are trying to use your {provider} account to log in', {
								values: { provider: getDisplay(showProviderPrompt) }
						  })
						: $_('Looks like you are trying to verify a {provider} account', {
								values: { provider: getDisplay(showProviderPrompt) }
						  })}
				</h3>
				{#if login}
					<p class="w-3/4 mx-auto mb-3 -mt-1 italic text-sm">
						{$_('Logging in with a social provider is generally more secure than email')}
					</p>
				{/if}
				<LoginProvider
					on:click={continueWithProvider(showProviderPrompt, {
						login_hint: email || undefined, ////dont send empty login_hint
						accountToUse,
						accountSelected,
						useDifferentManaged
					})}
					prefix="Continue with"
					provider={{ slug: showProviderPrompt, display: getDisplay(showProviderPrompt) }}
				/>
			</div>
		{/if}

		{#if verifiedEmails.includes(email)}
			<span
				data-test="email-exists-error"
				class="text-red-500 text-center text-sm mt-2 block"
				transition:slide|local>{$_('Email has already been verified')}</span
			>
		{/if}

		<button
			data-test="email-send-verification-btn"
			on:click={() => {
				ajaxRequestSend = true;
				verifyEmail(email);
			}}
			disabled={!isValidEmail(email) ||
				ajaxRequestSend ||
				verifiedEmails.includes(email) ||
				checkEmailProviderAjax}
			class="disabled:opacity-60 relative transition {showProviderPrompt
				? 'btn-border'
				: 'btn-background'} h-12 w-full inline-flex items-center justify-center"
			class:mt-3={!disabled || !login}
		>
			{#if ajaxRequestSend}
				<SpinnerIcon
					css="h-5 w-5 {showProviderPrompt ? 'text-charcoal dark:text-[#d4d4d4]' : 'text-white'}"
				/>
			{:else if login}
				{$_('Send verification code to log in')}
			{:else}
				{$_('Send verification code')}
			{/if}
		</button>
	</div>
{:else}
	<div class="text-center" in:fly={{ x: 20 }}>
		<div class="flex items-center justify-center">
			{#if disabled && login}
				<h1 class="text-lg text-center">{$_('Enter the 6-digit code you received')}</h1>
			{:else}
				<h1 class="text-lg text-center">
					{$_('Enter 6 digit code sent to {contact}', { values: { contact: email } })}
				</h1>
			{/if}
		</div>

		{#if !verifyOTPAjax}
			<SvelteOtp
				bind:value={otp}
				autofocus={true}
				numOfInputs={6}
				wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
				numberOnly={true}
				inputClass="text-lg !w-9 !h-11 !border-none"
			/>

			<button
				data-test="email-resend-verification-btn"
				on:click|preventDefault={() => {
					ajaxRequestResend = true;
					verifyEmail(email, true);
				}}
				disabled={ajaxRequestResend}
				class="focus:underline hover:underline relative h-5 text-sm inline-flex items-center opacity-80 justify-center font-medium"
			>
				{#if ajaxRequestResend}
					<SpinnerIcon css="h-4 w-4 block mx-auto" />
				{:else}
					{$_('Resend verification code')}
				{/if}
			</button>
		{:else}
			<div class="mx-auto h-20 flex justify-center items-center">
				<SpinnerIcon css="h-6 w-6 block mx-auto" />
			</div>
			<span class="h-6 block" />
		{/if}
	</div>
{/if}
